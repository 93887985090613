import React from 'react';
// Modules
import { NextPage } from 'next/types';
import { useForm } from 'react-hook-form';
// MUI Core
import { Alert, AlertTitle, Button, Container, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuth } from "../../lib/auth";


interface FormData {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));

export const Login: NextPage = () => {
  const { login } = useAuth();
  const { handleSubmit, register } = useForm<FormData>();
  const [error, setError] = React.useState(null);

  const classes = useStyles();
  const onSubmit = async (data : FormData) => {
    try {
      await login(data);
    } catch (err) {
      setError(err);
    }
  }

  return (
    <Container className={classes.container} maxWidth="xs">
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  autoComplete="username"
                  name="email"
                  size="small"
                  variant="outlined"
                  {...register('email', { required: true })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  autoComplete="current-password"
                  name="password"
                  size="small"
                  type="password"
                  variant="outlined"
                  {...register('password', { required: true })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" fullWidth type="submit" variant="contained">
              Log in
            </Button>
          </Grid>
        </Grid>
      </form>
      {error && (
        <Alert severity="error">
          <AlertTitle>Mauvais login / mot de passe</AlertTitle>
          <strong>Details techniques: </strong> {JSON.stringify(error)}
        </Alert>
      )}
    </Container>
  );
};

export default Login;