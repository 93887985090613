import { Switch } from "@mui/material";
import React, { useContext } from "react";
import Brightness2Icon from '@mui/icons-material/Brightness2';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { ThemeContext } from '../../CustomThemeProvider';
import { useStyles } from "./ThemeSwitcher.styles";

export const ThemeSwitcher = () => {

    const setThemeName = useContext(ThemeContext)
    const classes = useStyles();


    const [state, setState] = React.useState({
        checked: localStorage.getItem("appTheme") === 'darkTheme'
    });

    const handleCheck = () => {
        const newState = !state.checked
        setState({checked: newState});
        setThemeName(newState ? 'darkTheme' : 'lightTheme');
    }

    return (
        <div className={classes.themeSwitcherContent} onClick={handleCheck}>
            <WbSunnyIcon/>
            <Switch checked={state.checked} />
            <Brightness2Icon/>
        </div>
    )
}

export default ThemeSwitcher;