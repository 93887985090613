import React from 'react'
import { useParams } from 'react-router';
import { useStyles } from "./ProductEdition.styles";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  Stack,
  Button,
  Grid,
  IconButton,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  MenuItem
} from "@mui/material";

import { 
  Add,
  Save,
  Clear 
} from '@mui/icons-material';
import { useDeleteProductReferences, useProduct, useProductFamilies, useProductReferences } from '../../api';
import { Guid } from '../../lib/Guid';

interface RouteParams {
    productId: Guid
}

interface Reference{
  providerCode: string,
  ean: string
}

interface ProductReferenceForm {
  name: string,
  family: Guid,
  subFamily: Guid,
  goServiceCode: string,
  securityUrl: string,
  references: Reference[]
}

export const ProductEdition = () => {
  const params = useParams<RouteParams>();
  const classes = useStyles();
  const { status: statusPF, data: families } = useProductFamilies();
  const { status: statusP, data } = useProduct(params.productId);
  const { status: statusPR, data: productReferences } = useProductReferences(params.productId);
  const { control, getValues, reset, handleSubmit, setValue } = useForm({
      reValidateMode: "onBlur",
      defaultValues: {
        name: "",
        family: "",
        subfamily: "",
        goServiceCode: "",
        securityUrl: "",
        references: [],
      }
    });

  const {
      fields: references,
      append: appendReferenceRow,
      remove: removeReferenceRow,
      replace: replaceReferences,
    } = useFieldArray({
      control,
      name: "references"
    });
  
    const handleOnSubmit = (evt: ProductReferenceForm) => {
      console.log(evt);
    };

    const resetToDefaultValues = (evt: any) => {
      console.log("should reset values here");
    };

  const addNewMemeber = () => appendReferenceRow({ providerCode: "", ean: "" });
  // const familyWatch = useWatch({ control: control, name: "family", disabled: true });

  if (statusPF === 'loading' || statusP === 'loading' || statusPR === 'loading') return <h1>Loading...</h1>
  if (!data) return <span>Error: Could not load data</span>
  
  setValue("name", data.label, { shouldTouch: false });
  setValue("family", data.familyId.toString(), { shouldTouch: false });
  setValue("subfamily", data.subFamilyId.toString(), { shouldTouch: false });
  setValue("goServiceCode", data.goServiceCode, { shouldTouch: false });
  setValue("securityUrl", data.safetySheetURL, { shouldTouch: false });
  // replaceReferences(productReferences);

  
  console.log("Received families:", families);
  console.log("Received product:", data);
  console.log("Received product references:", productReferences);

  return (
    <div className={classes.subPage}>
      <Grid container spacing={3} component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid item spacing={3} xs={5}>
          <Card className={classes.root}>
            <CardHeader title="Informations" className={classes.cardHeader} />
            <Divider variant="middle" />
            <CardContent>
            <Stack spacing={3}>
            <Controller
                render={({ field }) => <TextField label="Nom" variant="outlined" {...field} />}
                name="name"
                control={control}
              />
              <Controller
                name="family"
                render={({ field }) =>
                  <TextField
                  variant="outlined"
                  {...field}
                  select
                  label="Famille"
                  >
                    {families?.filter(x => x.parentId.toString() === Guid.empty).map(x => <MenuItem key={x.id.toString()} value={x.id.toString()}>{x.label}</MenuItem>)}
                  </TextField>}
                control={control}
              />
              <Controller
                name="subfamily"
                render={({ field }) =>
                  <TextField
                  // disabled={!watchFamily}
                  variant="outlined"
                  {...field}
                  select
                  label="Sous-Famille"
                  >
                    {families?.filter(x => x.parentId.toString() === getValues("family")).map(x => <MenuItem key={x.id.toString()} value={x.id.toString()}>{x.label}</MenuItem>)}
                  </TextField>}
                control={control}
              />
              <Controller
                name="goServiceCode"
                render={({ field }) => <TextField label="Code Go Service" variant="outlined" {...field} />}
                control={control}
              />
              <Controller
                name="securityUrl"
                render={({ field }) => <TextField label="Lien fiche secu" variant="outlined" {...field} />}
                control={control}
              />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item spacing={3} xs={5}>
        <Card className={classes.root}>
          <CardHeader title="references" className={classes.cardHeader} />
          <Divider variant="middle" />
          <CardContent>
          <Stack spacing={2}>
            {references.map((field, index) => (
              <Grid container xs={12} alignItems={'center'}>
                <Grid item xs={11}>
                <Stack spacing={1}>
                  <Controller
                  render={({ field }) => <TextField fullWidth label="Code fournisseur" variant="outlined" {...field} />}
                  name={`references.${index}.providerCode`}
                  control={control}
                  />
                  <Controller
                    control={control}
                    // must use . for the object key!!!
                    name={`references.${index}.ean`}
                    render={({ field }) => (<TextField fullWidth label="EAN" variant="outlined"{... field}/>)}/>
                </Stack>
                </Grid>
                <Grid item alignContent={'center'} xs={1}>
                <IconButton
                  onClick={() => removeReferenceRow(index)}
                  edge="end">
                    <Clear />
                </IconButton>
                </Grid>
              </Grid>
            ))}
            <Button variant="contained" onClick={addNewMemeber} startIcon={<Add/>}>
              Add
            </Button>
            </Stack>
          </CardContent>
        </Card>
        </Grid>
        <Grid item container xs={2} spacing={2} alignContent={'center'} alignItems={'center'}>
          <Grid item xs alignContent={'center'}>
            <Button type="submit" variant="contained" startIcon={<Save/>}>
              Sauvegarder
            </Button>
          </Grid>
          <Grid item xs alignContent={'center'}>
            <Button variant="contained" onClick={resetToDefaultValues} startIcon={<Save/>}>
              Annuler
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}