import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { isLoggedIn, useAuth } from './lib/auth';


const PrivateRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { logout } = useAuth();

  if (isLoggedIn() !== true) {
    logout();
    return <div/>;
  }
  return (
    <Route {...rest}>
        <Component />
    </Route>
  );
};

export default PrivateRoute;