  
import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeCreator } from './themes/base';


export const ThemeContext = React.createContext((themeName: string): void => {});

const CustomThemeProvider: React.FC = (props) => {
    // Read current theme from localStorage
    const currentTheme = localStorage.getItem("appTheme") || "lightTheme";

    // State to hold the selected theme name
    const [themeNameState, _setThemeName] = useState(currentTheme);

    // Get the theme object by theme name
    const theme = themeCreator(themeNameState);

    const setThemeName = (newThemeName: string): void => {
      localStorage.setItem("appTheme", newThemeName);
      _setThemeName(newThemeName);
      }

    return (
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    );
}

export default CustomThemeProvider;