export const storage = {
    getToken: () => {
      let maybeToken = window?.localStorage?.getItem("token");
      if (maybeToken !== null) {
        return JSON.parse(maybeToken)
      }
      return null;
    },
    setToken: (token : string) =>
      window.localStorage.setItem("token", JSON.stringify(token)),
    clearToken: () => window.localStorage.removeItem("token")
  };
  