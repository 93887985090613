import React, { useState } from "react";
import { SideMenu } from '../SideMenu/SideMenu';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useStyles } from "./TopBar.styles";
import { ThemeSwitcher } from "../ThemeSwitcher/ThemeSwitcher";
import { useAuth } from "../../lib/auth";

const TopBar = () => {
    const { logout } = useAuth();
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    
    return (
        <>
        <AppBar 
        position="static"
        className={`${classes.appBar}, ${open ? classes.appBarShift: ''}`}
        >
            <Toolbar>
                <IconButton 
                    edge="start" 
                    className={`${classes.menuButton}, ${open ? classes.hide: ''}`}
                    aria-label="open drawer"
                    onClick={() => setOpen(true)}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6">
                    Go Service Back Office
                </Typography>
                <div className={classes.themeSwitcher}>
                    <ThemeSwitcher/>
                </div>
                <div className={`${open ? classes.logout: ''}`}>
                    <IconButton onClick={() => logout()}><LogoutIcon/></IconButton>
                </div>
            </Toolbar>
        </AppBar>
        <SideMenu open={open} onClose={() => setOpen(false)}/>
        </>
      );
}

export default TopBar;