import React from 'react'
import { useParams } from "react-router";
import { useStyles } from "./VisitPreview.styles";
import { Button, Card, CardContent, CardHeader, Divider, Typography, Grid }from '@mui/material';
import { useVisitDetails } from "../../api";



interface RouteParams {
    visitId: string
}

export const VisitPreview = () => {
    const params = useParams<RouteParams>();
    const classes = useStyles();

    const { status, data, error } = useVisitDetails(params.visitId);

    if (status === 'loading') return <h1>Loading...</h1>
    if (status === 'error') return <span>Error: {error.message}</span>

    return (
        <div className={classes.subPage}>
        <Button color='primary' variant="contained">Aller à la fiche client</Button>        
        <Grid container className={classes.root} spacing={5}>
            <Grid key="operations" item xs>
            <Card className={classes.root}>
                <CardHeader title="Opérations" className={classes.cardHeader} />
                <Divider variant="middle" />
                <CardContent>
                <div className={classes.list}>
                    {data?.soldOperations.map(x => <Typography align="center">{x}</Typography>)}
                </div>
                </CardContent>
            </Card>
            </Grid>
            <Grid key="produits" item xs>
            <Card className={classes.root}>
                <CardHeader title="Produits" className={classes.cardHeader} />
                <Divider variant="middle" />
                <CardContent>
                <div className={classes.list}>
                    {data?.soldProducts.map(x => <Typography align="center">{x.quantity} {x.label} {x.goServiceCode}</Typography>)}
                </div>
                </CardContent>
            </Card>
            </Grid>
            {data?.comments.length !== 0 ?
            <Grid key="Notes" item xs>
                <>
                {data?.comments.map(x =>
                                <Card className={classes.root}>
                                    <CardHeader title={x.label} className={classes.cardHeader} />
                                    <Divider variant="middle" />
                                    <CardContent>
                                        {x.message}
                                    </CardContent>
                                </Card>
                )}
                </>
            </Grid>
            : null
            }
            <Grid key="chimie" item xs>
            <Card className={classes.root}>
                <CardHeader title="Chimie" className={classes.cardHeader} />
                <Divider variant="middle" />
                <CardContent>
                <div className={classes.list}>
                    {data?.waterChemistry.map(x => <Typography align="center">{x.label} {x.value}</Typography>)}
                </div>
                </CardContent>
            </Card>
            </Grid>
        </Grid>
        </div>
    )
}
