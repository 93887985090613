import { initReactQueryAuth } from "react-query-auth";
import {
    LoginRequest,
    AuthResponse,
    User,
//   getUserProfile,
//   registerWithEmailAndPassword,
  loginWithEmailAndPassword
} from "../api";
import { storage } from "../utils";
import jwtDecode from "jwt-decode";
import { DateTime } from "luxon";

export async function handleUserResponse(data : AuthResponse) {
  const { token } = data;
  storage.setToken(token);
  return { name : 'dsi'} as User ;
}

export function isLoggedIn() : boolean {
  if (storage.getToken()) {
    const decodedToken = jwtDecode(storage.getToken());
    const timestamp = decodedToken.exp;
    const expDate = DateTime.fromSeconds(timestamp);
    if (DateTime.now().toUTC() >= expDate) {
        localStorage.clear();
        return false;
    }
    return true;
  }
    return false;
}

async function loadUser() {
  let user = {name : 'UNDEFINEDUSER'} as User;

  if (storage.getToken()) {
    // const data = await getUserProfile();
    // user = data;
    user = { name : 'dsi'} as User;
  }
  return user;
}

async function loginFn(data : LoginRequest) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data : LoginRequest) {
  return await loginFn(data);
}

async function logoutFn() {
  await storage.clearToken();
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn
};

const { AuthProvider, useAuth } = initReactQueryAuth<User>(authConfig);

export { AuthProvider, useAuth };
