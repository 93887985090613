import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    root: {
      borderRadius: 5,
      minWidth: 256,
      textAlign: 'center',
    },
    cardHeader: {
      textAlign: 'center',
      spacing: 10,
    },
    list: {
      padding: '20px',
    },
    subPage: {
      padding: '20px',
    },
  }));