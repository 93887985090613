import * as React from "react";
import { DataGrid, GridColDef, GridSortDirection, GridValueFormatterParams } from '@mui/x-data-grid';
import {
  Switch,
  useHistory
} from 'react-router-dom';
import { VisitPreview } from "../VisitPreview/VisitPreview";
import { useVisits } from "../../api";
import PrivateRoute from "../../privateRoute";
import { DateTime } from "luxon";

const DATE_FORMAT = 'f';

const columns: GridColDef[] = [
    { field: 'lastName', headerName: 'Nom', width: 250 },
    { field: 'firstName', headerName: 'Prenom', width: 250 },
    { field: 'date', headerName: 'Date', width: 150, type: 'dateTime',
      valueFormatter: (params: GridValueFormatterParams) => {
        // first converts to JS Date, then to locale option through date-fns
        const dateISO = params.value?.toString();
        if (dateISO === undefined)
          return params.value;
        return DateTime.fromISO(dateISO).setLocale('fr').toFormat(DATE_FORMAT);
      },
    },
    { field: 'postalCode', headerName: 'Code Postal', width: 130 },
    { field: 'city', headerName: 'Ville', width: 200 },
  ];

export const Visits = () => {
  const history = useHistory()
  const { status, data, error } = useVisits();

  if (status === 'loading') return <h1>Loading...</h1>
  if (status === 'error') return <span>Error: {error.message}</span>

  return (
        <>
        <div style={{ height: 630, width: '100%' }}>
          <DataGrid
            rows={data} 
            columns={columns} 
            pageSize={10} 
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = data.find((row) =>
                selectedIDs.has(row.id.toString())
              );
              history.push('/visits/visit/' + selectedRowData.id);
            }}
            sortModel={[{
              field: 'date',
              sort: 'desc' as GridSortDirection
            }]}
           />
        </div>
        <Switch>
          <PrivateRoute path="/Visits/visit/:visitId" component={VisitPreview} />
        </Switch>
        </>
    )
}
