import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import CustomThemeProvider from './CustomThemeProvider';
import TopBar from './components/TopBar/TopBar';
import { useAuth } from "./lib/auth";
import { Login } from "./components/Login/Login";


export function App() {
  return (
        <CustomThemeProvider>
          <CssBaseline />
          <Router>
            {useAuth().user?.name !== 'UNDEFINEDUSER' ? <TopBar/> : <Login/>}
          </Router>
        </CustomThemeProvider>
  );
}

export default App;
