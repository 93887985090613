import { storage } from "./utils";
import axios, { AxiosRequestConfig } from "axios";
import {
  useMutation,
  useQuery,
} from "react-query";
import { ProductDTO, ProductFamilyDTO, ProductReferenceDTO, VisitDetailsDTO } from "./dtos/VisitDTO";
import { Guid } from "./lib/Guid";

export interface AuthResponse {
  token: string;
}

export interface User {
  id: Guid;
  email: string;
  name?: string;
}

export interface LoginRequest {
    email: string,
    password: string
}

//const API_URL = "http://localhost:54413/api";
const API_URL = "https://alfred.goservice.org/api";

function getConfig(): AxiosRequestConfig {
  const token = storage.getToken()
  return {headers: { Authorization: `Bearer ${token}` }}
};

export async function handleApiResponse(response : Response) {
  const data = await response.json();

  if (response.ok) {
    return data;
  } else {
    return Promise.reject(data);
  }
}

export async function loginWithEmailAndPassword(data : LoginRequest): Promise<AuthResponse> {
  return window
    .fetch(`${API_URL}/AuthManagement/Login`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
      },
      mode: 'cors',
      body: JSON.stringify(data)
    })
    .then(handleApiResponse);
}

const getVisits = async () => {
  const { data } = await axios.get(
    `${API_URL}/visits/short`, getConfig()
  );
  return data;
};

export function useVisits() {
  return useQuery("visits", () => getVisits());
}

const getCatalog = async () => {
  const { data } = await axios.get(
    `${API_URL}/Product/short`, getConfig()
  );
  return data;
};

export function useCatalog() {
  return useQuery("product/short", () => getCatalog());
}

const getVisitDetails = async (visitId : Guid) => {
  const { data } = await axios.get<VisitDetailsDTO>(
    `${API_URL}/visits/${visitId}/details`, getConfig()
  );
  return data;
};

export function useVisitDetails(visitId : Guid) {
  return useQuery(["visitDetails", visitId], () => getVisitDetails(visitId));
}

const getProduct = async (productId : Guid) => {
  const { data } = await axios.get<ProductDTO>(
    `${API_URL}/product/${productId}`, getConfig()
  );
  return data;
};

export function useProduct(productId : Guid) {
  return useQuery(["product", productId], () => getProduct(productId));
}

const getProductFamilies = async () => {
  const { data } = await axios.get<ProductFamilyDTO[]>(
    `${API_URL}/productFamily`, getConfig()
  );
  return data;
};

export function useProductFamilies() {
  return useQuery(["productFamilies"], () => getProductFamilies());
}

const getProductReferences = async (productId : Guid) => {
  const { data } = await axios.get<ProductReferenceDTO[]>(
    `${API_URL}/productReference/byProductId/${productId}`, getConfig()
  );
  return data;
};

export function useProductReferences(productId : Guid) {
  return useQuery(["productReferences", productId], () => getProductReferences(productId));
}

const deleteProductReference = async (referenceId : Guid) => {
  const { data } = await axios.delete(
    `${API_URL}/productReference/${referenceId}/references`, getConfig()
  );
  return data;
};

export function useDeleteProductReferences(referenceId : Guid) {
  return useMutation(["deleteProductReference", referenceId], () => deleteProductReference(referenceId));
}
