import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Drawer, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material"
import { useStyles } from "./SideMenu.styles";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Switch, useHistory } from "react-router-dom";
import PrivateRoute from "../../privateRoute";
import { Visits } from "../Visits/Visits";
import { Customers } from "../Customers/Customers";
import { Products } from "../Catalog/Products";
import { Operations } from "../Catalog/Operations";

interface ISideMenu {
  open: boolean
  onClose: Function
}

export const SideMenu = (props: ISideMenu) => {
  const history = useHistory();
  const classes = useStyles();

    return (
      <>
          <main className={`${classes.content}, ${props.open ? classes.contentShift: ''}`}>
          <Drawer         
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => props.onClose()}>
              <ChevronLeftIcon /> 
            </IconButton>
          </div>
          <Divider/>
          <List>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant='h6'>Visites</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 'unset'}}>
                <List className={classes.accordionList} style={{ padding: 'unset'}}>
                  <ListItem button key={'Recentes'} onClick={() => history.push('/visits')}>
                    <ListItemText primary={'Recentes'} />
                  </ListItem>
                  <ListItem button key={'Toutes'} onClick={() => history.push('/visits')}>
                    <ListItemText primary={'Toutes'} />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography variant='h6'>Clients</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 'unset'}}>
                <List className={classes.accordionList} style={{ padding: 'unset'}}>
                  <ListItem button key={'Fiches clients'} onClick={() => history.push('/customers')}>
                    <ListItemText primary={'Fiches clients'} />
                  </ListItem>
                  <ListItem button key={'Tournees'} onClick={() => history.push('/customers')}>
                    <ListItemText primary={'Tournees'} />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography variant='h6'>Catalogue</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 'unset'}}>
                <List className={classes.accordionList} style={{ padding: 'unset'}}>
                  <ListItem button key={'Produits'} onClick={() => history.push('/catalog/products')}>
                    <ListItemText primary={'Produits'} />
                  </ListItem>
                  <ListItem button key={'Operations'} onClick={() => history.push('/catalog/operations')}>
                    <ListItemText primary={'Operations'} />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </List>
          </Drawer>
          <Switch>
              <PrivateRoute path="/visits" component={Visits}/>
              <PrivateRoute path="/customers" component={Customers}/>
              <PrivateRoute path="/catalog/products" component={Products}/>
              <PrivateRoute path="/catalog/operations" component={Operations}/>
            </Switch>
          </main>
      </>
    )
}
