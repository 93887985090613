import * as React from "react";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Switch,
  useHistory
} from 'react-router-dom';
import PrivateRoute from "../../privateRoute";
import { useCatalog } from "../../api";
import { ProductEdition } from "../ProductEdition/ProductEdition";

const columns: GridColDef[] = [
  { field: 'productId', hide: true },
  { field: 'name', headerName: 'Nom', width: 250 },
  { field: 'goServiceCode', headerName: 'Code Go Service', width: 250 },
  { field: 'ean', headerName: 'EANs', width: 250},
  { field: 'family', headerName: 'Famille', width: 250 },
  { field: 'subFamily', headerName: 'Sous-Famille', width: 250 },
  ];

export const Products = () => {
  const history = useHistory()
  const { status, data, error } = useCatalog();

  if (status === 'loading') return <h1>Loading...</h1>
  if (status === 'error') return <span>Error: {error.message}</span>

  return (
        <>
        <div style={{ height: 630, width: '100%' }}>
          <DataGrid
            getRowId={(row) => row.productId}
            rows={data} 
            columns={columns} 
            pageSize={10} 
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = data.find((row) =>
                selectedIDs.has(row.productId.toString())
              );
              history.push('/catalog/products/' + selectedRowData.productId);
            }}
           />
        </div>
        <Switch>
          <PrivateRoute path="/catalog/products/:productId" component={ProductEdition} />
        </Switch>
        </>
    )
}
